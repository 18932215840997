import React, { useEffect, useState } from 'react'
import MediaRow from '../../common/MediaRow'
import SearchBar from './components/SearchBar'
import SearchBarScrolling from './components/SearchBarScrolling'
import { useExploreLazyQuery } from '../../graphql'
import { useDispatch, useSelector } from 'react-redux'
import { selectWindowSize } from '../../store/base/commonSlice'
import { WindowSize } from '../../constants/enums'
import LoadingScreen from '../../common/LoadingScreen'
import debounce from 'lodash.debounce'
import { selectExploreAll, setExploreAll } from '../../store/exploreAll/exploreAllSlice'

type ExplorePage = {
  scrolling: boolean
  brandedName: string
}

const ExplorePage = ({ scrolling }: ExplorePage) => {
  const dispatch = useDispatch()
  const exploreAllState = useSelector(selectExploreAll)
  const windowSize = useSelector(selectWindowSize)
  const [search, setSearch] = useState('')

  const [explore, { data, loading }] = useExploreLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    explore()
  }, [])

  const debounceHandleSearch = debounce(handleSearch, 500)

  async function handleSearch() {
    dispatch(
      setExploreAll({
        open: exploreAllState.open,
        id: exploreAllState.id,
        catalogId: exploreAllState.catalogId,
        search: search,
      }),
    )
    await explore({
      variables: {
        data: {
          search,
        },
      },
    })
  }

  useEffect(() => {
    debounceHandleSearch()
  }, [search])

  const contentMatchesSearch = data?.explore?.rows.some((row) => row?.items.list.length)

  return (
    <div style={styles.container}>
      {!scrolling ? (
        <div style={{ position: 'fixed', top: 15, width: '100%' }}>
          <SearchBar text={search} setSearch={setSearch} />
        </div>
      ) : (
        <div style={{ position: 'fixed', top: 15, width: '100%', zIndex: 2 }}>
          <SearchBarScrolling text={search} setSearch={setSearch} />
        </div>
      )}
      {search === '' ? (
        <div style={windowSize !== WindowSize.Mobile ? styles.header : styles.headerMobile}>Explore</div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={
              windowSize !== WindowSize.Mobile
                ? { ...styles.searchResultsHeaderDivider, marginLeft: '10%' }
                : { ...styles.searchResultsHeaderDividerMobile, marginLeft: 25 }
            }
          />
          <div style={windowSize !== WindowSize.Mobile ? styles.searchHeader : styles.searchHeaderMobile}>
            {loading ? 'LOADING' : contentMatchesSearch ? 'TOP RESULTS' : 'NO RESULTS'}
          </div>
          <div
            style={
              windowSize !== WindowSize.Mobile
                ? { ...styles.searchResultsHeaderDivider, marginRight: '10%' }
                : { ...styles.searchResultsHeaderDividerMobile, marginRight: 25 }
            }
          />
        </div>
      )}
      <LoadingScreen loading={loading && !data}>
        <div className={'exploreWrapper'} style={styles.container}>
          <div className={'mediaRowWrapper'} style={styles.mediaRowWrapper}>
            {data?.explore?.rows.map((row, index) => {
              if (row?.items.list.length) {
                return <MediaRow key={index} {...row} />
              }
            })}
          </div>
        </div>
      </LoadingScreen>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  backgroundImage: {
    backgroundImage:
      'linear-gradient(to bottom, transparent, #0D0D0D), url(https://i.picsum.photos/id/397/1920/1080.jpg?hmac=edw4LTaXPWwrkbGBZjjHmToKh4Y1Wd8ASEaFbqeKiyY)',
    backgroundRepeat: 'cover',
    height: '55vh',
    width: '100%',
    zIndex: 1,
  } as React.CSSProperties,
  backgroundImageMobile: {
    backgroundImage:
      'linear-gradient(to bottom, transparent, #0D0D0D), url(https://i.picsum.photos/id/397/1920/1080.jpg?hmac=edw4LTaXPWwrkbGBZjjHmToKh4Y1Wd8ASEaFbqeKiyY)',
    backgroundRepeat: 'cover',
    height: '22vh',
    width: '100%',
    zIndex: 1,
  } as React.CSSProperties,
  searchResultsHeaderDivider: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#404040',
    marginTop: 305,
    height: 1,
    zIndex: 1,
    width: '250%',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexShrink: 2,
  } as React.CSSProperties,
  searchResultsHeaderDividerMobile: {
    backgroundColor: '#404040',
    width: '105%',
    marginTop: 305,
    height: 1,
    zIndex: 1,
  } as React.CSSProperties,
  header: {
    color: 'white',
    marginTop: 300,
    marginRight: 5,
    marginLeft: 5,
    fontWeight: 700,
    fontSize: 64,
    zIndex: 1,
    opacity: 1,
    cursor: 'hover',
    flexWrap: 'nowrap',
  } as React.CSSProperties,
  headerMobile: {
    color: 'white',
    fontWeight: 700,
    fontSize: 28,
    backgroundColor: 'transparent',
    width: '100%',
    zIndex: 1,
    marginTop: 250,
    opacity: 1,
    cursor: 'hover',
    marginLeft: 5,
    marginRight: 5,
    flexWrap: 'nowrap',
  } as React.CSSProperties,
  searchHeader: {
    color: 'white',
    marginTop: 295,
    fontWeight: 500,
    fontSize: 18,
    width: '100%',
    zIndex: 1,
    opacity: 1,
    letterSpacing: 3.6,
    cursor: 'hover',
  } as React.CSSProperties,
  searchHeaderMobile: {
    color: 'white',
    marginTop: 295,
    fontWeight: 500,
    fontSize: 14,
    width: '100%',
    zIndex: 1,
    opacity: 1,
    letterSpacing: 2.8,
    cursor: 'hover',
  } as React.CSSProperties,
  mediaRowWrapper: {
    overflow: 'hidden',
    width: '100%',
    marginTop: '44px',
    marginBottom: '44px',
    backgroundColor: 'transparent',
  } as React.CSSProperties,
}

export default ExplorePage
