import { GateType, MediaItemFieldsFragment } from '../graphql'
import { useSelector } from 'react-redux'
import { formatAmount, formatInterval } from './products'
import { selectSession } from '../store/auth/sessionSlice'
import { selectUser } from '../store/auth/userSlice'
import checkSubscription from './checkSubscription'

function getPaywallText(mediaItem: MediaItemFieldsFragment | null | undefined, singleLine: true): string

function getPaywallText(
  mediaItem: MediaItemFieldsFragment | null | undefined,
  singleLine?: false,
): {
  paywallText: string[]
  skipFirstLine: boolean
}

function getPaywallText(
  mediaItem: MediaItemFieldsFragment | null | undefined,
  singleLine = false,
):
  | string
  | {
      paywallText: string[]
      skipFirstLine: boolean
    } {
  const { signedIn } = useSelector(selectSession)
  const user = useSelector(selectUser)
  const purchased = mediaItem?.purchased

  const paywallText: string[] = []
  let skipFirstLine = false

  if (mediaItem?.appliedGates.length) {
    if (mediaItem?.appliedGates.find((g) => g.type === GateType.Registration) && !signedIn) {
      paywallText.push('Sign in or register for an account to view this content.')
      if (singleLine) return paywallText[0]
    }

    const ppvGates = mediaItem.appliedGates.filter((g) => g.type === GateType.Ppv)
    const subscriptionGates = mediaItem.appliedGates.filter((g) => g.type === GateType.Subscription)

    // Todo: Logic to determine if purchased = true
    if (process.env.REACT_APP_STAGE !== 'prod' && process.env.REACT_APP_STAGE !== 'beta') {
      ppvGates.forEach((gate) => {
        const price = formatAmount(gate?.product?.defaultPrice?.amount)
        // hide ppv paywall in beta and dev
        paywallText.push(`Available on-demand ${price ? `for ${price}` : ''}`)
      })
    } else if (!signedIn && !subscriptionGates.length) {
      paywallText.push('Sign in or register for an account to view this content.')
      if (singleLine) return paywallText[0]
    }

    if (!paywallText.length && mediaItem.appliedGates.length > 1) {
      skipFirstLine = true
    } else if (singleLine && paywallText.length) {
      return paywallText[0]
    }

    if (!purchased) {
      if (!checkSubscription(user, mediaItem)) {
        if (singleLine && mediaItem.appliedGates.length > 1) {
          const cheapestGate = mediaItem.appliedGates
            .filter((g) => g.type === GateType.Subscription)
            .sort((a, b) => ((a.product?.defaultPrice?.amount || 0) > (b.product?.defaultPrice?.amount || 0) ? 1 : -1))
            .find((g) => g)
          const price = formatAmount(cheapestGate?.product?.defaultPrice?.amount)
          const interval = formatInterval(cheapestGate?.product?.defaultPrice?.recurring?.interval)
          return `Available starting at ${price}/${interval}`
        }
        // subscription paywall
        subscriptionGates.forEach((gate) => {
          const price = formatAmount(gate.product?.defaultPrice?.amount)
          const interval = formatInterval(gate.product?.defaultPrice?.recurring?.interval)
          paywallText.push(
            `${gate.product?.name}${mediaItem.appliedGates.length === 1 ? ' Exclusive' : ''} · ${price}/${interval}`,
          )
        })
        // if there was only one subscription paywall, return that if singleLine is true
        if (singleLine) return paywallText[0]
      }
    }
  }
  return {
    paywallText,
    skipFirstLine,
  }
}

export default getPaywallText
