import React, { useEffect, useRef, useState } from 'react'
import { Controls } from '@vidstack/react'
import MiniModeProvider, { useMiniMode } from './MiniModeProvider'
import MiniVideoControls from './MiniVideoControls'
import FullVideoControls from './FullVideoControls'
import MobileVideoControls from './MobileVideoControls'
import { useSelector } from 'react-redux'
import { selectPlayer } from '../../../store/video/playerSlice'

const VideoLayout = () => {
  const [miniMode] = useMiniMode()
  const [mobile, setMobile] = useState(false)
  const mobileRef = useRef(mobile)
  const { src } = useSelector(selectPlayer)

  useEffect(() => {
    mobileRef.current = mobile
  }, [mobile])

  const onResize = () => {
    if (window.innerWidth <= 480) {
      if (!mobileRef.current) {
        setMobile(true)
      }
    } else {
      if (mobileRef.current) {
        setMobile(false)
      }
    }
  }

  useEffect(() => {
    if (src && !miniMode) {
      const body = document.querySelector('body')
      if (body) {
        body.style.overflowY = 'hidden'
      }
    } else {
      const body = document.querySelector('body')
      if (body) {
        body.style.overflowY = 'scroll'
      }
    }
  }, [src, miniMode])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    setTimeout(() => {
      try {
        onResize()
      } catch (e) {
        console.log(e)
      }
    }, 50)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <Controls.Root className={'vds-controls'}>
      <MiniModeProvider>
        <MiniVideoControls />
        {mobile ? <MobileVideoControls /> : <FullVideoControls />}
      </MiniModeProvider>
    </Controls.Root>
  )
}

export default VideoLayout
