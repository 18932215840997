import { GateType, MediaItemFieldsFragment } from '../graphql'
import checkSubscription from './checkSubscription'
import { UserState } from '../store/auth/userSlice'

type MediaItemUserAccess = {
  showRegistration: boolean
  showPpv: boolean
  showSubscription: boolean
}

const mediaItemUserAccess = (
  user: UserState,
  signedIn: boolean,
  mediaItem?: MediaItemFieldsFragment | null,
): MediaItemUserAccess => {
  let showRegistration = false
  let showPpv = false
  let showSubscription = false

  // if the media item is purchased, then we don't need to check anything else
  if (mediaItem?.purchased) {
    return {
      showRegistration,
      showPpv,
      showSubscription,
    }
  }

  if (mediaItem?.appliedGates.length) {
    const isSubscribed =
      !!mediaItem?.appliedGates.find((g) => g.type === GateType.Subscription) && checkSubscription(user, mediaItem)

    // if the user is subscribed to a subscription gate that is assigned to this media item, then we don't need to check anything else
    if (isSubscribed) {
      return {
        showRegistration,
        showPpv,
        showSubscription,
      }
    }

    showPpv = !!mediaItem?.appliedGates.find((g) => g.type === GateType.Ppv)
    showSubscription =
      !!mediaItem?.appliedGates.find((g) => g.type === GateType.Subscription) && !checkSubscription(user, mediaItem)

    if (
      mediaItem?.appliedGates.find((g) => g.type === GateType.Registration) &&
      !signedIn &&
      !showPpv &&
      !showSubscription
    ) {
      showRegistration = true
    }
  }

  return {
    showRegistration,
    showPpv,
    showSubscription,
  }
}

export default mediaItemUserAccess
