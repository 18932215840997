import { client, handleErrors } from './index'
import { LiveNowDocument, LiveNowQuery } from '../graphql'
import { ApolloQueryResult, ObservableQuery } from '@apollo/client'

let query: ObservableQuery<unknown> | undefined

function start(pollInterval = 5000) {
  if (!query) {
    query = client?.watchQuery({
      query: LiveNowDocument,
      fetchPolicy: 'network-only',
    })
    const subscription = query?.subscribe({
      error: () => {
        // Retry service on error
        subscription?.unsubscribe()
        start()
      },
    })
  }
  query?.startPolling(pollInterval)
}

function stop() {
  query?.stopPolling()
}

function update(pollInterval: number) {
  query?.stopPolling()
  start(pollInterval)
}

export async function fetchLiveNow(
  fetchPolicy: 'network-only' | 'cache-only' = 'network-only',
): Promise<ApolloQueryResult<LiveNowQuery>> {
  const res = await handleErrors(
    client?.query({
      query: LiveNowDocument,
      fetchPolicy,
    }),
  )
  if (!res) throw new Error('No response')
  return res
}

export default {
  start,
  stop,
  update,
}
