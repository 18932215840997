import { useMeQuery } from '../graphql'
import { useDispatch } from 'react-redux'
import { setUser } from '../store/auth/userSlice'

const PollMe = () => {
  const dispatch = useDispatch()

  useMeQuery({
    // Todo: use the apollo cache instead and reference the user via fragments rather than using redux store
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    onCompleted: (data) => {
      const me = data?.me
      if (me) {
        dispatch(
          setUser({
            id: me.id,
            avatar: '',
            userName: `${me.firstName} ${me.lastName}`,
            firstName: me.firstName,
            lastName: me.lastName,
            email: me.email,
            authority: me.roles,
            subscriptions: me.customer?.subscriptions,
          }),
        )
      }
    },
  })

  return null
}

export default PollMe
