import React, { useEffect, useState } from 'react'
import './index.css'
import { brandColor } from '../../style/brandConfig'
import { useNavigate } from 'react-router-dom'
import Button from '../../common/buttons/Button'
import { useCreatePortalSessionMutation, useUpdatePasswordMutation, useUpdateMeMutation } from '../../graphql'
import { ApolloError } from '@apollo/client'
import { useSelector } from 'react-redux'
import { selectSession } from '../../store/auth/sessionSlice'
import { selectUser } from '../../store/auth/userSlice'

const Account = () => {
  const navigate = useNavigate()
  const { signedIn } = useSelector(selectSession)
  const user = useSelector(selectUser)

  if (!signedIn) {
    navigate('/')
  }

  const [type, setType] = useState('account')

  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [emailAddress, setEmailAddress] = useState(user.email)

  const [verifyCode, setVerifyCode] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [deleteInput, setDeleteInput] = useState('')

  const [invalidFirstName, setInvalidFirstName] = useState(false)
  const [invalidLastName, setInvalidLastName] = useState(false)
  const [invalidEmailAddress, setInvalidEmailAddress] = useState(false)
  const [invalidCurrentPassword, setInvalidCurrentPassword] = useState<string | null>(null)
  const [invalidNewPassword, setInvalidNewPassword] = useState(false)
  const [invalidConfirmNewPassword, setInvalidConfirmNewPassword] = useState(false)

  const [editName, setEditName] = useState(false)
  const [editEmail, setEditEmail] = useState(false)

  const matchedVerifyCode = '123456'

  const handleBack = () => {
    setType('account')

    setVerifyCode('')
    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
    setDeleteInput('')

    setInvalidFirstName(false)
    setInvalidLastName(false)
    setInvalidEmailAddress(false)
    setInvalidCurrentPassword(null)
    setInvalidNewPassword(false)
    setInvalidConfirmNewPassword(false)

    setEditName(false)
    setEditEmail(false)
  }

  const inputs = document.querySelectorAll('input.accountCodeInput') as NodeListOf<HTMLInputElement>

  useEffect(() => {
    inputs.forEach((element, index) => {
      element.addEventListener('keydown', (e) => {
        const target = e?.target as HTMLInputElement
        if (e.keyCode === 8 && target?.value === '') inputs[Math.max(0, index - 1)].focus()
        handleSetVerifyCode()
      })
      element.addEventListener('input', (e) => {
        const target = e?.target as HTMLInputElement
        if (target) {
          // TODO Figure out lint error
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const [first, ...rest] = target ? target.value : ''
          target.value = first ?? ''
          const last = index === inputs.length - 1
          const inserted = first !== undefined
          if (inserted && !last) {
            inputs[index + 1].focus()
            inputs[index + 1].value = rest.join('')
            inputs[index + 1].dispatchEvent(new Event('input'))
          }
        }
        handleSetVerifyCode()
      })
    })
  })

  const handleSetVerifyCode = () => {
    let code = [] as string[]
    inputs.forEach(({ value }) => code.push(value))
    setVerifyCode(code.join(''))
  }

  /*const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  }*/

  const [updateMeMutation] = useUpdateMeMutation()

  const [updatePasswordMutation] = useUpdatePasswordMutation()

  const [createPortalSessionMutation] = useCreatePortalSessionMutation()

  const handleManageBilling = async () => {
    const { data: portalData } = await createPortalSessionMutation({
      variables: {
        data: {
          returnUrl: `${window.location.origin}/account`,
        },
      },
    })
    if (portalData?.createPortalSession?.url) {
      window.location.href = portalData.createPortalSession.url
    }
  }

  const onSaveEditName = async () => {
    await updateMeMutation({
      variables: {
        data: {
          firstName,
          lastName,
        },
      },
    })
    setEditName(false)
  }

  const onSaveEditEmail = async () => {
    await updateMeMutation({
      variables: {
        data: {
          email: emailAddress,
        },
      },
    })
    setEditEmail(false)
  }

  const onSaveChangePassword = async () => {
    try {
      await updatePasswordMutation({
        variables: {
          data: {
            currentPassword,
            newPassword,
          },
        },
      })
      setType('success-password')
    } catch (e) {
      const error = e as ApolloError
      if (error.message === 'Your current password is incorrect') {
        setInvalidCurrentPassword(error.message)
      }
    }
  }

  const accountScreen = () => {
    return (
      <div style={styles.accountContainer}>
        <div style={styles.accountHeaderContainer}>
          <div style={styles.accountDivider} />
          <span style={styles.accountHeader}>ACCOUNT</span>
          <div style={styles.accountDivider} />
        </div>
        <div>
          <div style={styles.accountNameContainer}>
            <span style={styles.accountFormHeader}>My Information</span>
            <div style={styles.accountName}>
              {editName ? (
                <div style={{ display: 'flex' }}>
                  <div style={styles.accountInputContainer}>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      autoComplete={'off'}
                      placeholder={'First Name'}
                      required
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value)
                        if (e.target.value) {
                          setInvalidFirstName(false)
                        } else {
                          setInvalidFirstName(true)
                        }
                      }}
                      style={{
                        ...styles.accountInput,
                        border: `3px solid ${invalidFirstName ? '#FF4848' : 'transparent'}`,
                        width: 150,
                        height: 45,
                        marginRight: 15,
                      }}
                    />
                    {invalidFirstName && <span style={styles.accountErrorText}>Enter your first name.</span>}
                  </div>
                  <div style={styles.accountInputContainer}>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      autoComplete={'off'}
                      placeholder={'Last Name'}
                      required
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value)
                        if (e.target.value) {
                          setInvalidLastName(false)
                        } else {
                          setInvalidLastName(true)
                        }
                      }}
                      style={{
                        ...styles.accountInput,
                        border: `3px solid ${invalidLastName ? '#FF4848' : 'transparent'}`,
                        width: 150,
                        height: 45,
                        marginRight: 15,
                      }}
                    />
                    {invalidLastName && <span style={styles.accountErrorText}>Enter your last name.</span>}
                  </div>
                </div>
              ) : (
                <span style={styles.accountMiscText}>{firstName + ' ' + lastName}</span>
              )}
              <div style={styles.accountEditContainer}>
                {editName ? (
                  <>
                    <div style={styles.accountSaveBtn} onClick={onSaveEditName}>
                      <div className={'accountSaveBtnIcon'} />
                    </div>
                    <div
                      style={styles.accountCancelBtn}
                      className={'accountCancelBtn'}
                      onClick={() => setEditName(false)}>
                      <div className={'accountCancelBtnIcon'} />
                    </div>
                  </>
                ) : (
                  <div style={styles.accountEditBtn} className={'accountEditBtn'} onClick={() => setEditName(true)}>
                    <div className={'accountEditBtnIcon'} />
                  </div>
                )}
              </div>
            </div>
            <div style={styles.accountName}>
              {editEmail ? (
                <div style={{ display: 'flex' }}>
                  <div style={styles.accountInputContainer}>
                    <input
                      type="text"
                      id="emailAddress"
                      name="emailAddress"
                      autoComplete={'off'}
                      placeholder={'Email Address'}
                      required
                      value={emailAddress}
                      onChange={(e) => {
                        setEmailAddress(e.target.value)
                        if (e.target.value) {
                          if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
                            setInvalidEmailAddress(false)
                          } else {
                            setInvalidEmailAddress(true)
                          }
                        } else {
                          setInvalidEmailAddress(true)
                        }
                      }}
                      style={{
                        ...styles.accountInput,
                        width: 361,
                        height: 45,
                        marginRight: 15,
                        border: `3px solid ${invalidEmailAddress ? '#FF4848' : 'transparent'}`,
                      }}
                    />
                    {invalidEmailAddress && <span style={styles.accountErrorText}>Enter a valid email address.</span>}
                  </div>
                </div>
              ) : (
                <span style={styles.accountMiscText}>{emailAddress}</span>
              )}
              <div style={styles.accountEditContainer}>
                {editEmail ? (
                  <>
                    <div style={styles.accountSaveBtn} onClick={onSaveEditEmail}>
                      <div className={'accountSaveBtnIcon'} />
                    </div>
                    <div
                      style={styles.accountCancelBtn}
                      className={'accountCancelBtn'}
                      onClick={() => setEditEmail(false)}>
                      <div className={'accountCancelBtnIcon'} />
                    </div>
                  </>
                ) : (
                  <div style={styles.accountEditBtn} className={'accountEditBtn'} onClick={() => setEditEmail(true)}>
                    <div className={'accountEditBtnIcon'} />
                  </div>
                )}
              </div>
            </div>
            <div style={{ marginTop: 70 }}>
              <Button text={'change password'} width={'100%'} height={36} onClick={() => setType('change-password')} />
            </div>
            <div style={{ marginTop: 25 }}>
              <Button text={'manage billing'} width={'100%'} height={36} onClick={handleManageBilling} />
            </div>
            {process.env.REACT_APP_STAGE !== 'prod' && process.env.REACT_APP_STAGE !== 'beta' ? (
              <span style={{ ...styles.accountMiscText, justifyContent: 'center', marginTop: 30 }}>
                <a style={styles.accountLink} onClick={() => setType('delete-account')}>
                  Delete Account
                </a>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  const verifyEmailScreen = () => {
    return (
      <div style={{ ...styles.accountContainer, width: 440 }}>
        <div style={styles.accountVerifyEmailContainer}>
          <span style={styles.accountFormHeader}>Verify New Email</span>
          <span style={styles.accountMiscText}>We&apos;ve sent a code to {emailAddress}.</span>
          <span style={{ ...styles.accountMiscText, marginTop: 25 }}>Enter it below to verify your email address:</span>
          <fieldset className={'accountCodeContainer'} style={styles.accountCodeContainer}>
            <div className={'accountCode'} style={styles.accountCode}>
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'accountCodeInput'}
                style={styles.accountCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'accountCodeInput'}
                style={styles.accountCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'accountCodeInput'}
                style={styles.accountCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'accountCodeInput'}
                style={styles.accountCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'accountCodeInput'}
                style={styles.accountCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
              <input
                type={'number'}
                name="code"
                autoComplete={'off'}
                className={'accountCodeInput'}
                style={styles.accountCodeInput}
                minLength={1}
                maxLength={1}
                required
              />
            </div>
            {verifyCode.length === 6 && verifyCode !== matchedVerifyCode && (
              <span style={styles.accountErrorText}>Invalid code. Please try again, or resend code below.</span>
            )}
          </fieldset>
          <div style={{ marginTop: 60 }}>
            <Button
              text={'verify'}
              backgroundColor={brandColor}
              disabled={!verifyCode || verifyCode !== matchedVerifyCode}
              width={'100%'}
              height={36}
              onClick={() => setType('success-email')}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Button text={'cancel'} width={'100%'} height={36} onClick={handleBack} />
          </div>
          <span style={{ ...styles.accountMiscText, marginTop: 25 }}>
            Didn&apos;t receive a code? <a style={{ ...styles.accountLink, marginLeft: 4 }}>Resend Email</a>
          </span>
        </div>
      </div>
    )
  }

  const changePasswordScreen = () => {
    return (
      <div style={{ ...styles.accountContainer, width: 440 }}>
        <div style={styles.accountHeaderContainer}>
          <div style={styles.accountDivider} />
          <span style={styles.accountHeader}>ACCOUNT</span>
          <div style={styles.accountDivider} />
        </div>
        <div style={styles.accountNameContainer}>
          <span style={{ ...styles.accountMiscText, marginBottom: 12, cursor: 'pointer' }} onClick={handleBack}>
            <div style={styles.btnLeftArrow} />
            Back
          </span>
          <span style={styles.accountFormHeader}>Reset Password</span>
          <div style={styles.accountInputContainer}>
            <label style={styles.accountInputLabel}>Current Password</label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              autoComplete={'off'}
              placeholder={'At least six digits'}
              required
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value)
                if (e.target.value && e.target.value.length >= 6) {
                  setInvalidCurrentPassword(null)
                } else {
                  setInvalidCurrentPassword('Invalid current password')
                }
              }}
              style={{
                ...styles.accountInput,
                border: `3px solid ${invalidCurrentPassword ? '#FF4848' : 'transparent'}`,
                width: 396,
                height: 50,
                marginTop: 10,
              }}
            />
            {invalidCurrentPassword && <span style={styles.accountErrorText}>{invalidCurrentPassword}</span>}
          </div>
          <div
            style={{
              ...styles.accountInputContainer,
              marginTop: 30,
            }}>
            <label style={styles.accountInputLabel}>New Password</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              autoComplete={'off'}
              required
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value)
                if (e.target.value && e.target.value.length >= 6) {
                  setInvalidNewPassword(false)
                } else {
                  setInvalidNewPassword(true)
                }
              }}
              style={{
                ...styles.accountInput,
                border: `3px solid ${invalidNewPassword ? '#FF4848' : 'transparent'}`,
                width: 396,
                height: 50,
                marginTop: 10,
              }}
            />
            {invalidNewPassword && (
              <span style={styles.accountErrorText}>Password must contain at least six digits.</span>
            )}
          </div>
          <div
            style={{
              ...styles.accountInputContainer,
              marginTop: 30,
            }}>
            <label style={styles.accountInputLabel}>Confirm Password</label>
            <input
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              autoComplete={'off'}
              required
              value={confirmNewPassword}
              onChange={(e) => {
                setConfirmNewPassword(e.target.value)
                if (e.target.value && e.target.value === newPassword) {
                  setInvalidConfirmNewPassword(false)
                } else {
                  setInvalidConfirmNewPassword(true)
                }
              }}
              style={{
                ...styles.accountInput,
                border: `3px solid ${invalidConfirmNewPassword ? '#FF4848' : 'transparent'}`,
                width: 396,
                height: 50,
                marginTop: 10,
              }}
            />
            {invalidConfirmNewPassword && <span style={styles.accountErrorText}>Passwords must match.</span>}
          </div>
          <div style={{ marginTop: 75 }}>
            <Button
              text={'reset'}
              backgroundColor={brandColor}
              disabled={
                !currentPassword ||
                !newPassword ||
                !confirmNewPassword ||
                !!invalidCurrentPassword ||
                invalidNewPassword ||
                invalidConfirmNewPassword ||
                newPassword !== confirmNewPassword
              }
              width={'100%'}
              height={36}
              onClick={onSaveChangePassword}
            />
          </div>
          {/*{true (*/}
          {/*<span style={{ ...styles.accountErrorText, position: 'relative', display: 'flex', bottom: 0 }}>*/}
          {/*  An error occurred. Please try again.*/}
          {/*</span>*/}
          {/*)}*/}
        </div>
      </div>
    )
  }

  const deleteAccountScreen = () => {
    return (
      <div style={{ ...styles.accountContainer, width: 440 }}>
        <div style={{ ...styles.accountVerifyEmailContainer, height: 560 }}>
          <span style={styles.accountFormHeader}>Delete Account</span>
          <span style={styles.accountMiscText}>
            Are you sure you want to permanently delete your account? All of your history, favorites, and purchases will
            be lost.
          </span>
          <div
            style={{
              ...styles.accountInputContainer,
              marginTop: 30,
            }}>
            <label style={styles.accountInputLabel}>Enter Your Password</label>
            <input
              type="password"
              id="currentPassword"
              name="currentPassword"
              autoComplete={'off'}
              required
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value)
                if (e.target.value) {
                  setInvalidCurrentPassword(null)
                } else {
                  setInvalidCurrentPassword('Invalid current password')
                }
              }}
              style={{
                ...styles.accountInput,
                border: `3px solid ${invalidCurrentPassword ? '#FF4848' : 'transparent'}`,
                width: 330,
                height: 50,
                marginTop: 10,
              }}
            />
            {invalidCurrentPassword && (
              <span style={{ ...styles.accountErrorText, bottom: -33 }}>
                Incorrect password. Please check your spelling and try again.
              </span>
            )}
          </div>
          <div
            style={{
              ...styles.accountInputContainer,
              marginTop: 45,
            }}>
            <label style={styles.accountInputLabel}>Type DELETE to Confirm</label>
            <input
              type="text"
              id="delete"
              name="delete"
              autoComplete={'off'}
              required
              value={deleteInput}
              onChange={(e) => {
                setDeleteInput(e.target.value)
              }}
              style={{
                ...styles.accountInput,
                border: '3px solid transparent',
                width: 330,
                height: 50,
                marginTop: 10,
              }}
            />
          </div>
          <div style={{ marginTop: 60 }}>
            <Button
              text={'delete account'}
              backgroundColor={'#FF4848'}
              disabled={deleteInput !== 'DELETE'}
              width={'100%'}
              height={36}
              onClick={() => setType('success-delete')}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Button text={'cancel'} width={'100%'} height={36} onClick={handleBack} />
          </div>
        </div>
      </div>
    )
  }

  const successEmailScreen = () => {
    return (
      <div style={{ ...styles.accountContainer, width: 440, height: 475 }}>
        <div style={styles.accountHeaderContainer}>
          <div style={styles.accountDivider} />
          <span style={styles.accountHeader}>ACCOUNT</span>
          <div style={styles.accountDivider} />
        </div>
        <div style={styles.accountNameContainer}>
          <span style={{ ...styles.accountMiscText, marginBottom: 12, cursor: 'pointer' }} onClick={handleBack}>
            <div style={styles.btnLeftArrow} />
            Back
          </span>
          <div style={styles.accountVerifiedStatusContainer}>
            <div style={styles.accountVerifiedStatus}>
              <div className={'accountCheckmark'} />
            </div>
          </div>
          <span style={styles.accountFormHeader}>Success!</span>
          <span style={{ ...styles.accountMiscText, justifyContent: 'center', marginTop: 30 }}>
            Your email has been updated.
          </span>
        </div>
      </div>
    )
  }

  const successPasswordScreen = () => {
    return (
      <div style={{ ...styles.accountContainer, width: 440, height: 475 }}>
        <div style={styles.accountHeaderContainer}>
          <div style={styles.accountDivider} />
          <span style={styles.accountHeader}>ACCOUNT</span>
          <div style={styles.accountDivider} />
        </div>
        <div style={styles.accountNameContainer}>
          <span style={{ ...styles.accountMiscText, marginBottom: 12, cursor: 'pointer' }} onClick={handleBack}>
            <div style={styles.btnLeftArrow} />
            Back
          </span>
          <div style={styles.accountVerifiedStatusContainer}>
            <div style={styles.accountVerifiedStatus}>
              <div className={'accountCheckmark'} />
            </div>
          </div>
          <span style={styles.accountFormHeader}>Success!</span>
          <span style={{ ...styles.accountMiscText, justifyContent: 'center', marginTop: 30 }}>
            Your password has been updated.
          </span>
        </div>
      </div>
    )
  }

  const successDeleteScreen = () => {
    return (
      <div style={{ ...styles.accountContainer, width: 440, height: 475 }}>
        <div style={styles.accountHeaderContainer}>
          <div style={styles.accountDivider} />
          <span style={styles.accountHeader}>ACCOUNT</span>
          <div style={styles.accountDivider} />
        </div>
        <div style={styles.accountNameContainer}>
          <div style={styles.accountVerifiedStatusContainer}>
            <div style={styles.accountVerifiedStatus}>
              <div className={'accountCheckmark'} />
            </div>
          </div>
          <span style={{ ...styles.accountFormHeader, marginBottom: 24 }}>Sorry to see you go!</span>
          <span style={{ ...styles.accountMiscText, justifyContent: 'center' }}>Your account has been deleted.</span>
          <div style={{ marginTop: 60 }}>
            <Button
              text={'browse content'}
              width={'100%'}
              height={36}
              onClick={() => {
                setType('account')
                navigate('/')
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderScreen = () => {
    switch (type) {
      case 'account':
        return accountScreen()
      case 'verify-email':
        return verifyEmailScreen()
      case 'change-password':
        return changePasswordScreen()
      case 'delete-account':
        return deleteAccountScreen()
      case 'success-email':
        return successEmailScreen()
      case 'success-password':
        return successPasswordScreen()
      case 'success-delete':
        return successDeleteScreen()
      default:
        return accountScreen()
    }
  }

  return (
    <div style={styles.container} className={'accountContainer'}>
      {renderScreen()}
    </div>
  )
}

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  accountContainer: {
    width: 474,
    zIndex: 1,
  } as React.CSSProperties,
  accountHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 50,
  } as React.CSSProperties,
  accountDivider: {
    backgroundColor: '#404040',
    width: '100%',
    height: 1,
  } as React.CSSProperties,
  accountHeader: {
    color: '#FFFFFF',
    marginRight: 15,
    marginLeft: 15,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 2.8,
    textTransform: 'uppercase',
    opacity: 1,
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  accountNameContainer: {} as React.CSSProperties,
  accountName: {
    display: 'flex',
    height: 45,
    marginBottom: 26,
  } as React.CSSProperties,
  accountFullName: {} as React.CSSProperties,
  accountFormHeader: {
    fontSize: 24,
    color: '#FFFFFF',
    fontWeight: 700,
    width: '100%',
    textAlign: 'center',
    marginBottom: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  accountInput: {
    width: 304,
    height: 44,
    fontSize: 19,
    borderRadius: 5,
    border: 'none',
    padding: '0px 20px',
    background: 'rgba(255, 255, 255, 0.1)',
  } as React.CSSProperties,
  accountInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
  } as React.CSSProperties,
  accountLink: {
    borderBottom: '1px solid #FFFFFF',
    fontWeight: 500,
    color: '#FFFFFF',
    cursor: 'pointer',
  } as React.CSSProperties,
  accountErrorText: {
    textAlign: 'left',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0,
    color: '#FF4848',
    opacity: 1,
    marginTop: 8,
    width: '100%',
    position: 'absolute',
    bottom: -19,
    left: 0,
  } as React.CSSProperties,
  accountBtn: {
    width: '100%',
    height: 39,
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  accountBtnText: {
    fontSize: 14,
    letterSpacing: 1.4,
    color: '#FFFFFF',
    opacity: 1,
    fontWeight: 500,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  } as React.CSSProperties,
  accountMiscText: {
    textAlign: 'left',
    fontSize: 17,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  } as React.CSSProperties,
  accountEditContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifySelf: 'flex-end',
  } as React.CSSProperties,
  accountSaveBtn: {
    cursor: 'pointer',
    position: 'relative',
    height: 20,
    width: 20,
    marginRight: 8,
  } as React.CSSProperties,
  accountCancelBtn: {
    cursor: 'pointer',
    position: 'relative',
    height: 20,
    width: 20,
  } as React.CSSProperties,
  accountEditBtn: {
    cursor: 'pointer',
    position: 'relative',
    height: 20,
    width: 20,
  } as React.CSSProperties,
  accountCodeContainer: {
    border: 'none',
    padding: 0,
    margin: 0,
    position: 'relative',
  } as React.CSSProperties,
  accountCode: {
    display: 'flex',
    marginTop: 25,
  } as React.CSSProperties,
  accountCodeInput: {
    width: 50,
    height: 70,
    border: 'none',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 5,
    opacity: 1,
    textAlign: 'center',
    fontSize: 46,
    letterSpacing: 0,
    color: '#FFFFFF',
    marginRight: 10,
  } as React.CSSProperties,
  accountVerifyEmailContainer: {
    width: 375,
    height: 504,
    background: '#262626 0% 0% no-repeat padding-box',
    boxShadow: '0px 6px 25px #000000BF',
    border: '1px solid #404040',
    borderRadius: 5,
    opacity: 1,
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    padding: 45,
  } as React.CSSProperties,
  accountVerifiedStatus: {
    width: 23,
    height: 23,
    background: '#55B685',
    opacity: 1,
    borderRadius: '50%',
  } as React.CSSProperties,
  accountVerifiedStatusContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  } as React.CSSProperties,
  btnRightArrow: {
    border: 'solid white',
    borderWidth: '0px 3px 3px 0px',
    display: 'inline-block',
    padding: 2.75,
    marginLeft: 4,
    transform: 'rotate(-45deg)',
  } as React.CSSProperties,
  btnLeftArrow: {
    border: 'solid white',
    borderWidth: '0px 3px 3px 0px',
    display: 'inline-block',
    padding: 2.75,
    marginRight: 4,
    transform: 'rotate(135deg)',
  } as React.CSSProperties,
  accountInputLabel: {
    fontSize: 17,
    color: '#FFFFFF',
    fontWeight: 700,
    width: '100%',
    textAlign: 'left',
  } as React.CSSProperties,
}

export default Account
