import React, { CSSProperties, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import MediaRow from '../common/MediaRow'
import { brandColor } from '../style/brandConfig'
import { usePageQuery } from '../graphql'
import LoadingScreen from '../common/LoadingScreen'
import { useSelector } from 'react-redux'
import { selectWindowSize } from '../store/base/commonSlice'
import { WindowSize } from '../constants/enums'

const CustomPage = () => {
  const windowSize = useSelector(selectWindowSize)

  const params = useParams()

  const { data, loading } = usePageQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        slug: params?.pageSlug,
      },
    },
  })

  let item = data?.page

  useEffect(() => {
    if (item) {
      let backdrop = document?.querySelector('.bottomLayer') as HTMLElement
      if (item?.bannerImage?.src && backdrop) {
        backdrop.style.backgroundColor = '#0D0D0D'
      }
      return () => {
        if (backdrop) {
          backdrop.style.backgroundColor = brandColor
        }
      }
    }
  }, [item])

  const backgroundImageStyle: React.CSSProperties =
    windowSize === WindowSize.Mobile ? { ...styles.backgroundImageMobile } : { ...styles.backgroundImage }

  if (item?.bannerImage) {
    backgroundImageStyle.background = `no-repeat center/cover url('${item.bannerImage.src}')`
    delete backgroundImageStyle.backgroundColor
  }

  const fadeImage: CSSProperties =
    windowSize === WindowSize.Mobile
      ? {
          top: 105,
          left: 0,
          right: 0,
          height: 45,
          position: 'absolute',
          width: '100vw',
          zIndex: 1,
          background:
            'transparent linear-gradient(180deg, #000000 0%, #00000000 0%, #0D0D0D 85%, #0D0D0D 100%, #545454 100%) 0% 0% no-repeat padding-box',
        }
      : {
          display: 'none',
        }

  const gradientBottom: CSSProperties =
    windowSize === WindowSize.Mobile
      ? {
          top: 75,
          left: 0,
          right: 0,
          height: 525,
          position: 'absolute',
          width: '100vw',
          zIndex: 1,
          background:
            'transparent linear-gradient(0deg, #0D0D0D 0%, #0D0D0DBF 0%, #0D0D0DBE 64%, #0D0D0D00 100%) 0% 0% no-repeat padding-box',
        }
      : {
          top: 174,
          left: 0,
          right: 0,
          height: 1493,
          position: 'absolute',
          width: '100vw',
          zIndex: 1,
          background: 'transparent linear-gradient(0deg, #0D0D0D 72%, #0D0D0D00 100%) 0% 0% no-repeat padding-box',
        }

  const scrollBackground: CSSProperties =
    windowSize === WindowSize.Mobile
      ? {
          top: 150,
          left: 0,
          right: 0,
          height: 'calc(100vh - 350px)',
          position: 'absolute',
          width: '100vw',
          zIndex: 1,
          background: '#0D0D0D',
        }
      : {
          top: 174 + 1493,
          left: 0,
          right: 0,
          height: 'calc(100vh - 350px)',
          position: 'absolute',
          width: '100vw',
          zIndex: 1,
          background: '#0D0D0D',
        }

  const topGradient: CSSProperties =
    windowSize === WindowSize.Mobile
      ? {
          top: 0,
          left: 0,
          right: 0,
          height: 110,
          position: 'fixed',
          width: '100vw',
          zIndex: 2,
          background: 'transparent linear-gradient(180deg, #0D0D0D 0%, #0D0D0D00 100%) 0% 0% no-repeat padding-box',
        }
      : {
          top: 0,
          left: 0,
          right: 0,
          height: 348,
          position: 'fixed',
          width: '100vw',
          zIndex: 2,
          background: 'transparent linear-gradient(180deg, #0D0D0D 0%, #0D0D0D00 100%) 0% 0% no-repeat padding-box',
        }

  return (
    <LoadingScreen loading={loading && !data}>
      <div style={topGradient} />
      <div style={fadeImage} />
      <div style={gradientBottom} />
      <div style={scrollBackground} />
      <div className={'customWrapper'} style={styles.container}>
        <div style={{ height: '100%' }}>
          <div style={backgroundImageStyle}></div>
        </div>
        <div style={windowSize === WindowSize.Mobile ? styles.headerMobile : styles.header}>{item?.name}</div>
        <div style={{ zIndex: 2 }}>
          {item?.rows.map((row, index) => {
            if (row.items.list.length) {
              return <MediaRow key={index} {...row} />
            }
          })}
        </div>
      </div>
    </LoadingScreen>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  } as React.CSSProperties,
  backgroundImage: {
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    position: 'fixed',
    backgroundColor: '#0D0D0D',
  } as React.CSSProperties,
  backgroundImageMobile: {
    height: 150,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    position: 'fixed',
  } as React.CSSProperties,
  exploreBackgroundOverlayTop: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to top, transparent 50%, #0D0D0D 100%)',
    zIndex: 1,
  } as React.CSSProperties,
  exploreBackgroundOverlayCover: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#0D0D0D',
    transition: 'all .3s ease-in-out',
    zIndex: 1,
  } as React.CSSProperties,
  exploreBackgroundOverlayBottom: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, transparent 12%, #0D0D0D 75%)',
    zIndex: 1,
  } as React.CSSProperties,
  header: {
    color: 'white',
    marginTop: 300,
    fontWeight: 700,
    fontSize: 64,
    width: '100%',
    zIndex: 1,
    opacity: 1,
    cursor: 'hover',
  } as React.CSSProperties,
  headerMobile: {
    color: 'white',
    fontWeight: 700,
    fontSize: 28,
    backgroundColor: 'transparent',
    width: '100%',
    zIndex: 1,
    marginTop: 100,
    opacity: 1,
    cursor: 'hover',
  } as React.CSSProperties,
}

export default CustomPage
