import React from 'react'
import './index.css'
import ProgressBar from '../../../ProgressBar'
import { useNavigate } from 'react-router-dom'
import Button from '../../../buttons/Button'
import { GateType, MediaItemFieldsFragment } from '../../../../graphql'
import ActionButtons from '../../../../common/ActionButtons'
import getPaywallText from '../../../../utils/paywallText'
import useLiveNow from '../../../../utils/hooks/useLiveNow'
import mediaItemUserAccess from '../../../../utils/mediaItemUserAccess'
import { useSelector } from 'react-redux'
import { selectSession } from '../../../../store/auth/sessionSlice'
import { selectUser } from '../../../../store/auth/userSlice'

type SlideProps = {
  mediaItem: MediaItemFieldsFragment | null | undefined
}

const Slide = ({ mediaItem }: SlideProps) => {
  const navigate = useNavigate()
  const liveNow = mediaItem ? useLiveNow(mediaItem.id) : null

  const item = mediaItem

  if (item) {
    const purchased = item.purchased // temp value
    const type = item?.__typename?.toLowerCase()

    //const now = new Date()
    //const startTime = new Date(item?.scheduledStart ? item?.scheduledStart : null)

    const duration = item.duration || 0
    const { signedIn } = useSelector(selectSession)
    const user = useSelector(selectUser)
    const { showPpv, showSubscription } = mediaItemUserAccess(user, signedIn, item)

    let header = type === 'event' ? 'PAST EVENT' : 'VIDEO'

    let eventStatus: 'live' | 'upcoming' | 'past' | undefined
    let timeRemainingText = ''
    let upcomingText = ''
    let durationText = ''
    if (liveNow) {
      timeRemainingText = liveNow.minutesRemainingText
      //const timeElapsedText = liveNow.minutesElapsedText

      eventStatus = 'live'
      header = 'ONGOING EVENT'
    } else if (item.__typename === 'Event') {
      const now = new Date()
      const start = new Date(item.scheduledStart)
      const end = new Date(item.scheduledEnd)

      durationText += `${start.toLocaleString('default', {
        month: 'long',
      })} ${start.getDate()}, ${now.getFullYear()}`

      if (now < start) {
        header = 'UPCOMING EVENT'
        eventStatus = 'upcoming'
        upcomingText = `Streaming live on ${start.toLocaleString('default', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}`
      } else if (now > end) {
        eventStatus = 'past'
      } else {
        eventStatus = 'live'
      }
    }

    /*if (itemStatus === 'past' || itemStatus === 'video') {
      timeLeft = (duration - progress) / 60
    }

    if (itemStatus === 'live' || item?.liveNow) {
      timeLeft = Math.abs(+duration - (+now - +startTime) / 1000) / 60
      progress = ((+now - +startTime) / 1000 / duration) * 100
    } else {
      progress = (progress / duration) * 100
    }

    timeLeft = +timeLeft.toFixed(0)*/

    const minutes = Math.floor(duration / 60)
    const seconds = duration - minutes * 60

    console.log(minutes.toString())
    console.log(seconds)

    //if (minutes) durationText += minutes.toString() + 'm'
    //if (seconds) durationText += ' ' + seconds.toString() + 's'

    const progressBarTopText = eventStatus === 'live' ? 'Streaming live now' : durationText

    const paywallText = getPaywallText(mediaItem, true)

    const progress = eventStatus !== 'upcoming' ? item.viewer?.progress || liveNow?.progress || 0 : 0

    return (
      <div className={'slideContainer'} style={styles.slideContainer}>
        <div className={'slide slideLeft'} style={styles.slideLeft}>
          <div className={'slideContent'} style={styles.slideContent}>
            <div className={'slideHeaderContainer'} style={styles.slideHeaderContainer}>
              <span className={'slideHeader'}>{header}</span>
              <div className={'slideHeaderDivider'} />
            </div>
            {item?.logo?.src ? (
              <div className={'slideLogoContainer'}>
                <img src={item?.logo?.src} alt={item?.logo?.src} className={'slideLogo'} />
              </div>
            ) : (
              <span className={'slideTitle'}>{item?.name}</span>
            )}
            <span className={'slideDescription'}>{item?.description}</span>
            <div className={'slideInfoContainer'}>
              <div className={'slideBtnContainer'} style={styles.slideBtnContainer}>
                <ActionButtons mediaItem={mediaItem} progress={progress} onSlider />
                <Button text={'details'} width={100} height={36} onClick={() => navigate(`/content/${item?.id}`)} />
              </div>
              <div className={'slideInfo'} style={styles.slideInfo}>
                {paywallText?.length ? (
                  <span className={'slideUpcomingText'} style={styles.slideUpcomingText}>
                    {mediaItem?.appliedGates.find((g) => g.type === GateType.Ppv) &&
                    mediaItem?.appliedGates.find((g) => g.type === GateType.Subscription) &&
                    process.env.REACT_APP_STAGE !== 'prod' &&
                    process.env.REACT_APP_STAGE !== 'beta'
                      ? ''
                      : paywallText}
                  </span>
                ) : (
                  <>
                    {eventStatus === 'upcoming' ? (
                      <span className={'slideUpcomingText'} style={styles.slideUpcomingText}>
                        {upcomingText}
                      </span>
                    ) : progress ? (
                      <div className={'slideProgressBarWrapper'} style={styles.slideProgressBarWrapper}>
                        <span className={'slideProgressBarTopText'} style={styles.slideProgressBarTopText}>
                          {progressBarTopText}
                        </span>
                        <div className={'slideProgressBarContainer'} style={styles.slideProgressBarContainer}>
                          <div
                            style={{
                              flex: 1,
                              alignItems: 'center',
                              position: 'relative',
                              justifyContent: 'center',
                              transform: 'translateY(4px)',
                            }}>
                            <ProgressBar progress={progress} live={eventStatus === 'live'} />
                          </div>
                          <div className={'slideProgressBarText'} style={styles.slideProgressBarText}>
                            {timeRemainingText}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span className={'slideDurationText'} style={styles.slideDurationText}>
                        {durationText}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={'slide slideRight'} style={styles.slideRight}>
          <div className={'slideImgContainer'} style={styles.slideImgContainer}>
            {eventStatus === 'live' ? (
              <div className={'carouselLiveIndicatorContainer'} style={styles.carouselLiveIndicatorContainer}>
                <div className={'carouselIndicator'} style={{ ...styles.carouselIndicator, background: '#FF4848' }} />
                <span className={'carouselIndicatorText'} style={styles.carouselIndicatorText}>
                  live
                </span>
              </div>
            ) : null}
            {!purchased && (showSubscription || showPpv) ? (
              <>
                <div className={'slideUnpurchasedContainer'} style={styles.slideUnpurchasedContainer} />
                <div className={'slideUnpurchasedIcon'} />
              </>
            ) : null}
            <img src={item?.sixteenNineCover?.src} alt={''} className={'slideImg'} style={styles.slideImg} />
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

const styles = {
  mediaRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '25px 90px',
    position: 'relative',
  } as React.CSSProperties,
  slideContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  slideLeft: {
    flex: 1,
  } as React.CSSProperties,
  slideRight: {
    boxSizing: 'border-box',
    aspectRatio: '16 / 9',
    flex: 1,
  } as React.CSSProperties,
  slideContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 35px',
    overflow: 'auto',
    width: '100%',
  } as React.CSSProperties,
  slideHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 56,
  } as React.CSSProperties,
  slideBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    zIndex: 1,
  } as React.CSSProperties,
  btnArrow: {
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: '13px solid #FFFFFF',
    marginRight: 13,
  } as React.CSSProperties,
  slideImgContainer: {
    position: 'relative',
    padding: '0 24px 0 0',
    width: '100%',
  } as React.CSSProperties,
  slideImg: {
    objectFit: 'cover',
    objectPosition: 'top',
    width: '100%',
    boxShadow: '15px 15px 10px #00000058',
  } as React.CSSProperties,
  carouselLiveIndicatorContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
    background: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 10,
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 20px',
    justifyContent: 'center',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  carouselIndicator: {
    width: 11,
    height: 11,
    borderRadius: '50%',
    marginRight: 15,
  } as React.CSSProperties,
  carouselIndicatorText: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1.4,
    color: '#FFFFFF',
    textTransform: 'uppercase',
  } as React.CSSProperties,
  slideUnpurchasedContainer: {
    position: 'absolute',
    right: 24,
    top: 0,
    borderTop: '108px solid #404040',
    borderLeft: '108px solid transparent',
    transition: 'all .2s ease-in-out',
  } as React.CSSProperties,
  slideInfo: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  } as React.CSSProperties,
  slideProgressBarWrapper: {
    margin: '0 auto',
    width: '100%',
    gap: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  } as React.CSSProperties,
  slideProgressBarContainer: {
    display: 'flex',
    gap: 20,
    maxWidth: 350,
    alignSelf: 'start',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 'auto',
    width: '100%',
  } as React.CSSProperties,
  slideProgressBarTopText: {
    color: '#FFFFFF',
    fontSize: 13,
    fontWeight: 500,
    width: '100%',
    textAlign: 'left',
  } as React.CSSProperties,
  slideProgressBarText: {
    color: '#808080',
    fontSize: 13,
    fontWeight: 500,
    justifyContent: 'end',
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  slideUpcomingText: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 13,
  } as React.CSSProperties,
  slideDurationText: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 13,
  } as React.CSSProperties,
}

export default Slide
