import { createSelector, createSlice } from '@reduxjs/toolkit'
import { WindowSize } from '../../constants/enums'
import { LinkFieldsFragment } from '../../graphql'

type CommonState = {
  currentRouteKey: string
  windowSize: WindowSize
  brandData?: LinkFieldsFragment
}

export const initialState: CommonState = {
  currentRouteKey: '',
  windowSize: WindowSize.DesktopFull,
  brandData: undefined,
}

export const commonSlice = createSlice({
  name: 'base/common',
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload
    },
    setWindowSize: (state, action) => {
      state.windowSize = action.payload
    },
    setBrandData: (state, action) => {
      state.brandData = action.payload
    },
  },
})

export const selectWindowSize = createSelector(
  (state: { base: { common: CommonState } }) => {
    return state.base.common.windowSize
  },
  (windowSize) => windowSize,
)

export const selectBrandData = createSelector(
  (state: { base: { common: CommonState } }) => {
    return state.base.common.brandData
  },
  (brandData) => brandData,
)

export const { setCurrentRouteKey, setWindowSize, setBrandData } = commonSlice.actions

export default commonSlice.reducer
