import React from 'react'
import './index.css'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../common/buttons/Button'
import { GateType, PriceFieldsFragment, useCreateCheckoutSessionMutation, useMediaItemQuery } from '../../graphql'
import { WindowSize } from '../../constants/enums'
import PpvInfo from './components/PpvInfo'
import SubscriptionInfo from './components/SubscriptionInfo'
import { useSelector } from 'react-redux'
import { selectBrandData, selectWindowSize } from '../../store/base/commonSlice'
import PricingInfo from './components/PricingInfo'
import checkSubscription from '../../utils/checkSubscription'
import { selectUser } from '../../store/auth/userSlice'
import LoadingScreen from '../../common/LoadingScreen'

const Products = () => {
  let productType = location.pathname.split('/')[1]

  const params = useParams()
  const user = useSelector(selectUser)
  const windowSize = useSelector(selectWindowSize)
  const brand = useSelector(selectBrandData)

  const contentId = params?.contentId
  if (!contentId) return null
  // const productId = params?.productId - unused for now, but could be used in the future for highlighting a subscription without navigating from content

  const navigate = useNavigate()

  const { data, loading } = useMediaItemQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        id: contentId,
      },
    },
  })

  const [createCheckoutSessionMutation] = useCreateCheckoutSessionMutation()

  const mediaItem = data?.mediaItem

  const filteredGates = mediaItem?.appliedGates.filter(
    (gate) => gate.type === (productType === 'purchase' ? GateType.Ppv : GateType.Subscription) && gate.product,
  )

  if (mediaItem) {
    if (mediaItem.purchased) {
      navigate(`/content/${contentId}`)
    }
    if (productType === 'subscribe' && checkSubscription(user, mediaItem)) {
      navigate(`/content/${contentId}`)
    }
  }

  const containerClassName = !filteredGates || filteredGates.length === 1 ? 'productContainer' : ''
  const brandStyle = windowSize === WindowSize.Mobile ? { ...styles.brand, ...styles.mobileBrand } : styles.brand
  const productSlideStyle =
    filteredGates?.length !== 1 ? { ...styles.productSlide, marginTop: 125, marginBottom: 100 } : styles.productSlide

  const goToStripe = async (price: PriceFieldsFragment | undefined) => {
    if (!price) return // todo: handle error
    const redirectUrlPathname = contentId
      ? productType === 'purchase'
        ? `/purchase/verify?contentId=${contentId}&checkoutSessionId={CHECKOUT_SESSION_ID}`
        : `/content/${contentId}`
      : '/'
    const { data: _data } = await createCheckoutSessionMutation({
      variables: {
        data: {
          priceId: price.id,
          contentId,
          redirectUrl: location.origin + redirectUrlPathname,
        },
      },
    })
    if (_data?.createCheckoutSession?.url) {
      window.location.href = _data.createCheckoutSession.url
    } else {
      // todo: handle error
    }
  }

  return (
    <LoadingScreen loading={loading}>
      <div className={containerClassName} style={styles.container}>
        <div style={brandStyle}>
          <img src={brand?.icon?.src} alt="brand" style={styles.logo} onClick={() => navigate('/')} />
        </div>
        <div className={'productSlide'} style={productSlideStyle}>
          <div style={styles.productHeaderContainer}>
            <span style={styles.productHeader}>{productType.toUpperCase()}</span>
            <div style={styles.productHeaderDivider} />
          </div>
          {filteredGates
            ?.map((gate, index) => {
              const productInputFormStyle =
                index < filteredGates.length - 1
                  ? { ...styles.productInputForm, marginBottom: 35 }
                  : styles.productInputForm
              return gate.product && gate.product.defaultPrice ? (
                <div key={index} style={productInputFormStyle}>
                  {productType === 'purchase' && mediaItem ? (
                    <PpvInfo mediaItem={mediaItem} />
                  ) : (
                    <SubscriptionInfo product={gate.product} />
                  )}
                  <PricingInfo price={gate.product.defaultPrice} />
                  <div style={{ marginTop: 30 }}>
                    <Button
                      text={`${productType === 'purchase' ? 'buy' : 'subscribe'} now`}
                      width={'100%'}
                      backgroundColor={'#FFFFFF'}
                      height={36}
                      onClick={() => goToStripe(gate.product?.defaultPrice ? gate.product.defaultPrice : undefined)}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Button
                      text={params?.contentId ? 'back' : 'not now'}
                      width={'100%'}
                      height={36}
                      arrowLeft={!!params?.contentId}
                      onClick={() => navigate(contentId ? `/content/${contentId}` : '/')}
                    />
                  </div>
                </div>
              ) : null
            })
            .filter((gate) => gate)}
        </div>
      </div>
    </LoadingScreen>
  )
}

const styles = {
  container: {
    width: '100vw',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  logoWrapper: {
    display: 'flex',
    marginLeft: 40,
    marginTop: 20,
    position: 'absolute',
    top: 0,
    left: 0,
    height: 65,
    width: 65,
  } as React.CSSProperties,
  brand: {
    height: 70,
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: 17,
    marginLeft: 35,
    cursor: 'pointer',
    alignItems: 'center',
  } as React.CSSProperties,
  mobileBrand: {
    display: 'flex',
    height: 40,
  } as React.CSSProperties,
  logo: {
    height: process.env.REACT_APP_BRAND_HEIGHT || '100%',
    cursor: 'pointer',
  } as React.CSSProperties,
  productSlide: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    minWidth: 350,
    maxWidth: 350,
  } as React.CSSProperties,
  productHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 30,
  } as React.CSSProperties,
  productHeader: {
    color: '#FFFFFF',
    marginRight: 15,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 2.8,
    textTransform: 'uppercase',
    opacity: 1,
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  productHeaderDivider: {
    backgroundColor: '#404040',
    width: '100%',
    height: 1,
  } as React.CSSProperties,
  productInputForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
}

export default Products

/*
<div style={styles.productInputForm}>
          <span style={styles.productNameText}>IGNATIUS+</span>
          <span style={{ ...styles.productMiscText, marginBottom: 20 }}>
            Description text ultrices neque ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae
            proin.
          </span>
          <span style={{ ...styles.productMiscText, marginTop: 10 }}>
            <div className={'checkmark'} style={{ marginRight: 10 }} />
            Ultrices neque ornare
          </span>
          <span style={{ ...styles.productMiscText, marginTop: 10 }}>
            <div className={'checkmark'} style={{ marginRight: 10 }} />
            Aenean euismod elementum
          </span>
          <span style={{ ...styles.productMiscText, marginTop: 10 }}>
            <div className={'checkmark'} style={{ marginRight: 10 }} />
            Nisi quis eleifend
          </span>
          <span style={{ ...styles.productMiscText, marginTop: 10 }}>
            <div className={'checkmark'} style={{ marginRight: 10 }} />
            Quam adipiscing vitae
          </span>
          <div style={styles.productPriceContainer}>
            <span style={styles.productPriceText}>$4.50</span>
            <span style={styles.productIntervalText}>/month</span>
          </div>
          <div style={{ marginTop: 30 }}>
            <Button
              text={'subscribe now'}
              width={'100%'}
              backgroundColor={'#FFFFFF'}
              height={36}
              onClick={() => navigate('/')}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              text={path ? 'back' : 'not now'}
              width={'100%'}
              height={36}
              arrowLeft={!!path}
              onClick={() => (path ? navigate(path, { state: { ...location?.state } }) : navigate('/'))}
            />
          </div>
        </div>
 */
