import React, { useState } from 'react'
import { brandColor } from '../../style/brandConfig'
import { hexToRgb } from '../../utils/colors'
import PlayIcon from '../../assets/Play_Icon.svg'

type Button = {
  text?: string
  backgroundColor?: string
  height?: number | string
  width?: number | string
  onClick?: () => void
  icon?: boolean
  arrowRight?: boolean
  arrowLeft?: boolean
  disabled?: boolean
}

const Button = (props: Button) => {
  const { text, onClick, backgroundColor, height, width, icon, arrowLeft, arrowRight, disabled } = props

  const [hover, setHover] = useState(false)
  const brandColorRgb = hexToRgb(brandColor)
  const hoverBackgroundColor = hover
    ? `rgb(${brandColorRgb?.r}, ${brandColorRgb?.g}, ${brandColorRgb?.b}, 0.3)`
    : 'transparent'

  const disabledStyles = {
    cursor: 'default',
  }
  const disabledButtonStyles = {
    ...disabledStyles,
    transform: 'scale(1)',
    border: '3px solid #808080',
    backgroundColor: '#808080',
  }

  return (
    <div
      className={'buttonContainer'}
      style={styles.buttonContainer}
      onClick={() => {
        if (!disabled && onClick) onClick()
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <div
        style={{
          ...styles.button,
          transform: hover ? 'scale(1.05)' : 'scale(1)',
          border: `3px solid ${backgroundColor ? backgroundColor : brandColor}`,
          backgroundColor: backgroundColor ? backgroundColor : hoverBackgroundColor,
          width,
          height,
          ...(disabled && disabledButtonStyles),
        }}
      />
      <span
        style={{
          ...styles.text,
          ...(disabled && disabledStyles),
          color:
            backgroundColor === '#FFFFFF' ? process.env.REACT_APP_LIGHT_BUTTON_TEXT_COLOR || brandColor : '#FFFFFF',
        }}>
        {arrowLeft && <div style={styles.leftArrow} />}
        {icon && <img src={PlayIcon} alt={''} style={styles.play} />}
        {text}
        {arrowRight && <div style={styles.rightArrow} />}
      </span>
    </div>
  )
}

const styles = {
  buttonContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  } as React.CSSProperties,
  button: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'all .3s ease-in-out',
    transformOrigin: 'center center',
    position: 'relative',
  } as React.CSSProperties,
  text: {
    position: 'absolute',
    fontSize: 14,
    letterSpacing: 1.8,
    fontWeight: 600,
    textAlign: 'center',
    cursor: 'pointer',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  play: {
    width: 10,
    height: 12,
    marginRight: 10,
  } as React.CSSProperties,
  rightArrow: {
    border: 'solid white',
    borderWidth: '0px 3px 3px 0px',
    display: 'inline-block',
    padding: 2.75,
    marginLeft: 4,
    transform: 'rotate(-45deg)',
  } as React.CSSProperties,
  leftArrow: {
    border: 'solid white',
    borderWidth: '0px 3px 3px 0px',
    display: 'inline-block',
    padding: 2.75,
    marginRight: 4,
    transform: 'rotate(135deg)',
  } as React.CSSProperties,
}

export default Button
