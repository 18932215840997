import React, { useState } from 'react'
import { useAddToWatchlistMutation, useRemoveFromWatchlistMutation } from '../../../graphql'
import RoundButton from '../../../common/buttons/RoundButton/RoundButton'

type WatchlistButtonProps = {
  contentId?: string
}
const WatchlistButton = ({ contentId }: WatchlistButtonProps) => {
  const [favorited, setFavorited] = useState(false)

  const [addToWatchlist] = useAddToWatchlistMutation({
    variables: {
      data: {
        contentId: contentId || '',
      },
    },
  })

  const [removeFromWatchlist] = useRemoveFromWatchlistMutation({
    variables: {
      data: {
        contentId: contentId || '',
      },
    },
  })

  const handleOnClick = async () => {
    if (!favorited) {
      try {
        await addToWatchlist()
      } catch (error) {
        console.error('Error adding to watchlist', error)
      }
    } else {
      try {
        await removeFromWatchlist()
      } catch (error) {
        console.error('Error removing from watchlist', error)
      }
    }
    setFavorited(!favorited)
  }

  return <RoundButton type="watchlist" onClick={handleOnClick} favorited={favorited} />
}

export default WatchlistButton
